

'use strict';
{
  const globals = this;
  const django = globals.django || (globals.django = {});

  
  django.pluralidx = function(n) {
    const v = n > 1;
    if (typeof v === 'boolean') {
      return v ? 1 : 0;
    } else {
      return v;
    }
  };
  

  /* gettext library */

  django.catalog = django.catalog || {};
  
  const newcatalog = {
    "%(num)s error": [
      "%(num)s erreur",
      "%(num)s erreurs"
    ],
    "%(num)s pixel": [
      "%(num)s pixel",
      "%(num)s pixels"
    ],
    "%(sel)s of %(cnt)s selected": [
      "%(sel)s sur %(cnt)s s\u00e9lectionn\u00e9",
      "%(sel)s sur %(cnt)s s\u00e9lectionn\u00e9s"
    ],
    "%s selected option not visible": [
      "%s option s\u00e9lectionn\u00e9e invisible",
      "%s options s\u00e9lectionn\u00e9es invisibles"
    ],
    "(%(number)s new items in this menu)": "(%(number)s  nouvel \u00e9l\u00e9ment dans ce menu)",
    "(1 new item in this menu)": "(1 nouvel \u00e9l\u00e9ment dans ce menu)",
    "(New)": "(Nouveau)",
    "6 a.m.": "6:00",
    "6 p.m.": "18:00",
    "Abbrechen": "Annuler",
    "Add a comment": "Ajouter un commentaire",
    "Aktualisieren": "Actualiser",
    "Alt text": "Texte alternatif",
    "Anzahl": "Quantit\u00e9",
    "April": "Avril",
    "Are you sure?": "\u00cates-vous s\u00fbr(e)\u00a0?",
    "Art.-Nr.": "N\u00b0 d'art.",
    "Artikel": "Article",
    "Artikelnummer, Name oder GTIN eingeben": "Saisir le num\u00e9ro d'article, le nom ou le GTIN",
    "August": "Ao\u00fbt",
    "Available %s": "%s disponible(s)",
    "Bestehende Adresse w\u00e4hlen": "Choisir une adresse existante",
    "Bestelllisten": "Listes de commande",
    "Blocks": "Blocs",
    "Broken link": "Lien mort",
    "Cancel": "Annuler",
    "Character count:": "Nombre de caract\u00e8res \u00a0:",
    "Choose": "Choisir",
    "Choose a Date": "Choisir une date",
    "Choose a Time": "Choisir une heure",
    "Choose a time": "Choisir une heure",
    "Choose all": "Tout choisir",
    "Chosen %s": "Choix des \u00ab\u00a0%s \u00bb",
    "Click to choose all %s at once.": "Cliquez pour choisir tous les \u00ab\u00a0%s\u00a0\u00bb en une seule op\u00e9ration.",
    "Click to remove all chosen %s at once.": "Cliquez pour enlever tous les \u00ab\u00a0%s\u00a0\u00bb en une seule op\u00e9ration.",
    "Close": "Fermer",
    "Collapse all": "Tout replier",
    "Comment": "Commenter",
    "Dashboard": "Tableau de bord",
    "Datum w\u00e4hlen": "Choisir la date",
    "December": "D\u00e9cembre",
    "Decorative image": "Image d\u00e9corative",
    "Delete": "Supprimer",
    "Delete error": "Supprimer l'erreur",
    "Deleting": "Suppression\u2026",
    "Der Warenkorb konnte nicht geladen werden.": "Le panier n'a pas pu \u00eatre charg\u00e9.",
    "Die Warenkorb-Optionen konnten nicht geladen werden.": "Les options du panier n'ont pas pu \u00eatre charg\u00e9es.",
    "Edit": "Modifier",
    "Edit '%(title)s'": "Modifier '%(title)s'",
    "Edit your account": "Modifiez votre compte",
    "Ein Fehler ist aufgetreten. Bitte laden Sie die Seite erneut und versuchen Sie es noch einmal.": "Une erreur est survenue. Veuillez recharger la page et r\u00e9essayer.",
    "Ein Produkt in ihrem Warenkorb hat ein zu fr\u00fches oder nicht gesetztes Lieferdatum.": "Un produit dans votre panier a une date de livraison trop pr\u00e9coce ou non d\u00e9finie.",
    "Enter your comments...": "Entrez votre commentaire\u2026",
    "Enter your reply...": "Entrez votre r\u00e9ponse\u2026",
    "Entfernen": "Supprimer",
    "Error while sending preview data.": "Une erreur est survenue lors de l'envoi des donn\u00e9es de pr\u00e9visualisation.",
    "Expand all": "Tout d\u00e9plier",
    "February": "F\u00e9vrier",
    "Filter": "Filtrer",
    "Focus comment": "Mettre le commentaire au premier-plan",
    "Fr\u00fchester Liefertermin": "Date de livraison au plus t\u00f4t",
    "GTIN": "GTIN",
    "Gesamt": "Total",
    "Gesamtbetr\u00e4ge": "Montants totaux",
    "Hide": "Masquer",
    "Horizontal line": "Ligne horizontale",
    "In den Warenkorb": "Ajouter au panier",
    "Informationen zum Produkt anzeigen": "Afficher les informations sur le produit",
    "Insert a block": "Ins\u00e9rer un bloc",
    "Ja": "Oui",
    "January": "Janvier",
    "Jetzt kostenlos bestellen": "Commander gratuitement maintenant",
    "July": "Juillet",
    "June": "Juin",
    "Lieferdatum": "Date de livraison",
    "Liefertermin w\u00e4hlen": "Choisir la date de livraison",
    "Lieferung": "Livraison",
    "Line break": "Retour \u00e0 la ligne",
    "Loading\u2026": "Chargement\u2026",
    "Main menu": "Menu principal",
    "March": "Mars",
    "May": "Mai",
    "Menge": "Quantit\u00e9",
    "Menge \u00e4ndern": "Modifier la quantit\u00e9",
    "Midnight": "Minuit",
    "Missing document": "Document manquant",
    "More actions": "Plus d'actions",
    "Nein": "Non",
    "Nettosumme": "Somme nette",
    "Neue Adresse anlegen": "Cr\u00e9er une nouvelle adresse",
    "No results": "Aucun r\u00e9sultat",
    "Noon": "Midi",
    "Note: You are %s hour ahead of server time.": [
      "Note\u00a0: l'heure du serveur pr\u00e9c\u00e8de votre heure de %s heure.",
      "Note\u00a0: l'heure du serveur pr\u00e9c\u00e8de votre heure de %s heures."
    ],
    "Note: You are %s hour behind server time.": [
      "Note\u00a0: votre heure pr\u00e9c\u00e8de l'heure du serveur de %s heure.",
      "Note\u00a0: votre heure pr\u00e9c\u00e8de l'heure du serveur de %s heures."
    ],
    "November": "Novembre",
    "Now": "Maintenant",
    "October": "Octobre",
    "Page": "Page",
    "Page explorer": "Explorateur",
    "Pages": "Pages",
    "Pin toolbar": "\u00c9pingler la barre d'outils",
    "Preis": "Prix",
    "Produkt nicht gefunden": "Produit non trouv\u00e9",
    "Produkte": "Produits",
    "Produkte in ihrem Warenkorb haben ein zu fr\u00fches oder nicht gesetztes Lieferdatum.": "Les produits de leur panier ont une date de livraison trop pr\u00e9coce ou non d\u00e9finie.",
    "Produktmuster und Infomaterial": "\u00c9chantillons et brochures",
    "Produktseite von": "Page produit de",
    "Rabatt": "R\u00e9duction",
    "Reload saved content": "Recharger le contenu sauvegard\u00e9",
    "Reload the page": "Recharger la page",
    "Remove": "Enlever",
    "Remove all": "Tout enlever",
    "Reply": "R\u00e9pondre",
    "Report this error to your website administrator with the following information:": "Signalez cette erreur \u00e0 votre webmaster avec les informations suivantes\u00a0:",
    "Resolve": "Archiver",
    "Retry": "R\u00e9essayer",
    "Save": "Enregistrer",
    "Save error": "Enregistrer l'erreur",
    "Save the page to add this comment": "Enregistrer la page pour ajouter ce commentaire",
    "Save the page to save this comment": "Enregistrer la page pour enregistrer ce commentaire",
    "Save the page to save this reply": "Enregistrer la page pour enregistrer ce commentaire",
    "Saving...": "Enregistrement\u2026",
    "Search": "Rechercher",
    "Search options\u2026": "Options de recherche\u2026",
    "See all": "Tout voir",
    "September": "Septembre",
    "Server Error": "Erreur de serveur",
    "Show": "Afficher",
    "Show error": "Afficher l\u2019erreur",
    "Show latest content": "Montrer le dernier contenu",
    "Speichern": "Enregistrer",
    "Split block": "Scinder le bloc",
    "Summe": "Total",
    "The editor just crashed. Content has been reset to the last saved version.": "L\u2019\u00e9diteur a rencontr\u00e9 un probl\u00e8me. Le dernier contenu sauvegard\u00e9 a \u00e9t\u00e9 restaur\u00e9.",
    "This field is required.": "Ce champ est requis.",
    "This is the list of available %s. You may choose some by selecting them in the box below and then clicking the \"Choose\" arrow between the two boxes.": "Ceci est une liste des \u00ab\u00a0%s\u00a0\u00bb disponibles. Vous pouvez en choisir en les s\u00e9lectionnant dans la zone ci-dessous, puis en cliquant sur la fl\u00e8che \u00ab\u00a0Choisir\u00a0\u00bb entre les deux zones.",
    "This is the list of chosen %s. You may remove some by selecting them in the box below and then clicking the \"Remove\" arrow between the two boxes.": "Ceci est la liste des \u00ab\u00a0%s\u00a0\u00bb choisi(e)s. Vous pouvez en enlever en les s\u00e9lectionnant dans la zone ci-dessous, puis en cliquant sur la fl\u00e8che \u00ab Enlever \u00bb entre les deux zones.",
    "Today": "Aujourd'hui",
    "Toggle side panel": "Afficher/masquer le panneau lat\u00e9ral",
    "Toggle sidebar": "Afficher/masquer la barre lat\u00e9rale",
    "Tomorrow": "Demain",
    "Type into this box to filter down the list of available %s.": "\u00c9crivez dans cette zone pour filtrer la liste des \u00ab\u00a0%s\u00a0\u00bb disponibles.",
    "Type into this box to filter down the list of selected %s.": "\u00c9crivez dans cette zone pour filtrer la liste des \u00ab\u00a0%s\u00a0\u00bb s\u00e9lectionn\u00e9\u00b7e\u00b7s.",
    "Umsatzsteuer": "TVA",
    "Unfocus comment": "Mettre le commentaire en arri\u00e8re-plan",
    "Unpin toolbar": "D\u00e9s\u00e9pingler la barre d'outils",
    "Versand": "Envoi",
    "View child pages of '%(title)s'": "Voir les sous-pages de '%(title)s'",
    "Weiter zur Kasse": "Passer \u00e0 la caisse",
    "Write something or type \u2018/\u2019 to insert a block": "\u00c9crivez quelque chose ou tapez \u00ab\u00a0/\u00a0\u00bb pour ins\u00e9rer un bloc",
    "Yesterday": "Hier",
    "You have selected an action, and you haven\u2019t made any changes on individual fields. You\u2019re probably looking for the Go button rather than the Save button.": "Vous avez s\u00e9lectionn\u00e9 une action, et vous n'avez fait aucune modification sur des champs. Vous cherchez probablement le bouton Envoyer et non le bouton Enregistrer.",
    "You have selected an action, but you haven\u2019t saved your changes to individual fields yet. Please click OK to save. You\u2019ll need to re-run the action.": "Vous avez s\u00e9lectionn\u00e9 une action, mais vous n'avez pas encore enregistr\u00e9 certains champs modifi\u00e9s. Cliquez sur OK pour enregistrer. Vous devrez r\u00e9appliquer l'action.",
    "You have unsaved changes on individual editable fields. If you run an action, your unsaved changes will be lost.": "Vous avez des modifications non sauvegard\u00e9es sur certains champs \u00e9ditables. Si vous lancez une action, ces modifications vont \u00eatre perdues.",
    "abbrev. month April\u0004Apr": "avr",
    "abbrev. month August\u0004Aug": "ao\u00fb",
    "abbrev. month December\u0004Dec": "d\u00e9c",
    "abbrev. month February\u0004Feb": "f\u00e9v",
    "abbrev. month January\u0004Jan": "jan",
    "abbrev. month July\u0004Jul": "jui",
    "abbrev. month June\u0004Jun": "jun",
    "abbrev. month March\u0004Mar": "mar",
    "abbrev. month May\u0004May": "mai",
    "abbrev. month November\u0004Nov": "nov",
    "abbrev. month October\u0004Oct": "oct",
    "abbrev. month September\u0004Sep": "sep",
    "gratis": "gratuit",
    "one letter Friday\u0004F": "V",
    "one letter Monday\u0004M": "L",
    "one letter Saturday\u0004S": "S",
    "one letter Sunday\u0004S": "D",
    "one letter Thursday\u0004T": "J",
    "one letter Tuesday\u0004T": "M",
    "one letter Wednesday\u0004W": "M",
    "\u00e4ndern": "changer"
  };
  for (const key in newcatalog) {
    django.catalog[key] = newcatalog[key];
  }
  

  if (!django.jsi18n_initialized) {
    django.gettext = function(msgid) {
      const value = django.catalog[msgid];
      if (typeof value === 'undefined') {
        return msgid;
      } else {
        return (typeof value === 'string') ? value : value[0];
      }
    };

    django.ngettext = function(singular, plural, count) {
      const value = django.catalog[singular];
      if (typeof value === 'undefined') {
        return (count == 1) ? singular : plural;
      } else {
        return value.constructor === Array ? value[django.pluralidx(count)] : value;
      }
    };

    django.gettext_noop = function(msgid) { return msgid; };

    django.pgettext = function(context, msgid) {
      let value = django.gettext(context + '\x04' + msgid);
      if (value.includes('\x04')) {
        value = msgid;
      }
      return value;
    };

    django.npgettext = function(context, singular, plural, count) {
      let value = django.ngettext(context + '\x04' + singular, context + '\x04' + plural, count);
      if (value.includes('\x04')) {
        value = django.ngettext(singular, plural, count);
      }
      return value;
    };

    django.interpolate = function(fmt, obj, named) {
      if (named) {
        return fmt.replace(/%\(\w+\)s/g, function(match){return String(obj[match.slice(2,-2)])});
      } else {
        return fmt.replace(/%s/g, function(match){return String(obj.shift())});
      }
    };


    /* formatting library */

    django.formats = {
    "DATETIME_FORMAT": "j F Y H:i",
    "DATETIME_INPUT_FORMATS": [
      "%d/%m/%Y %H:%M:%S",
      "%d/%m/%Y %H:%M:%S.%f",
      "%d/%m/%Y %H:%M",
      "%d.%m.%Y %H:%M:%S",
      "%d.%m.%Y %H:%M:%S.%f",
      "%d.%m.%Y %H:%M",
      "%Y-%m-%d %H:%M:%S",
      "%Y-%m-%d %H:%M:%S.%f",
      "%Y-%m-%d %H:%M",
      "%Y-%m-%d"
    ],
    "DATE_FORMAT": "j F Y",
    "DATE_INPUT_FORMATS": [
      "%d/%m/%Y",
      "%d/%m/%y",
      "%d.%m.%Y",
      "%d.%m.%y",
      "%Y-%m-%d"
    ],
    "DECIMAL_SEPARATOR": ",",
    "FIRST_DAY_OF_WEEK": 1,
    "MONTH_DAY_FORMAT": "j F",
    "NUMBER_GROUPING": 3,
    "SHORT_DATETIME_FORMAT": "j N Y H:i",
    "SHORT_DATE_FORMAT": "j N Y",
    "THOUSAND_SEPARATOR": "\u00a0",
    "TIME_FORMAT": "H:i",
    "TIME_INPUT_FORMATS": [
      "%H:%M:%S",
      "%H:%M:%S.%f",
      "%H:%M"
    ],
    "YEAR_MONTH_FORMAT": "F Y"
  };

    django.get_format = function(format_type) {
      const value = django.formats[format_type];
      if (typeof value === 'undefined') {
        return format_type;
      } else {
        return value;
      }
    };

    /* add to global namespace */
    globals.pluralidx = django.pluralidx;
    globals.gettext = django.gettext;
    globals.ngettext = django.ngettext;
    globals.gettext_noop = django.gettext_noop;
    globals.pgettext = django.pgettext;
    globals.npgettext = django.npgettext;
    globals.interpolate = django.interpolate;
    globals.get_format = django.get_format;

    django.jsi18n_initialized = true;
  }
};

